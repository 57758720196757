import React, { useReducer, useEffect, useCallback, useContext } from "react";
import { getContactAttributes, getLoyaltyConfig, getTiers } from '../Services';
import { UserContext } from './userContext';

const DataContext = React.createContext()

const ignoredAttributes = new Set(['created_by', '_modified_on', '_delete_protected', '_email_valid', '_mobile_number_valid']);
const initialState = {
    config: {
        rewards: {},
        points: {},
        // tiers: {}
    },
    enableEdit: false,
    isLoading: true,
    // selectedTier : newTierObj,
    // tierAction : 'Add',
    // isSave: false,
    isLoadingContactAttributes: true,
    contactAttributes: {

    },
    tags: [],
    isLoadingTiers: false,
    tiers: {}
}

const DataContextActions = {
    GET_LOYALTY_CONFIG: 'getLoyaltyConfig',
    //UPDATE_LOYALTY_CONFIG: 'updateLoyaltyConfig',
    SET_CONTACT_ATTRIBUTES: 'setContactAttributes',
    SET_LOADING_CONTACT_ATTRIBUTES: 'setLoadingContactAttributes',
    GET_TIERS: 'getTiers',
    SET_LOADING_TIERS: 'setLoadingTiers'
};

const reducer = (state, action) => {
    console.debug("Action:", action)
    switch (action.type) {
        case DataContextActions.GET_LOYALTY_CONFIG: {
            return {
                ...state,
                config: action.config,
                enableEdit: true,
                isLoading: false
            };
        }
        case DataContextActions.GET_TIERS: {
            return {
                ...state,
                tiers: action.tiers,

                isLoadingTiers: false
            };
        }
        // case DataContextActions.UPDATE_LOYALTY_CONFIG: {
        //     const updatedConfig = action.config

        //     const updateConfigs = state.config.map(configData => {
        //         if (configData._id === updatedConfig.id) {
        //             return updatedConfig
        //         }
        //         return configData
        //     })
        //     return {
        //         ...state,
        //         config: updateConfigs
        //     }
        // }

        case DataContextActions.SET_LOADING_TIERS: {
            return {
                ...state,
                isLoadingTiers: action.status
            }
        }
        case DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES: {
            return {
                ...state,
                isLoadingContactAttributes: action.status
            }
        }
        case DataContextActions.SET_CONTACT_ATTRIBUTES: {
            return {
                ...state,
                contactAttributes: Object.entries(action.contactAttributes).reduce((result, [key, value]) => {
                    if (!ignoredAttributes.has(key)) {
                        result[key] = value;
                    }
                    return result;
                }, {}),
                tags: action.tags || [],
                isLoadingContactAttributes: false
            }
        }
        default:
            return state;
    }
};

const DataContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isAuth } = useContext(UserContext);

    const setConfig = useCallback((newConfig) => {
        dispatch({ type: DataContextActions.GET_LOYALTY_CONFIG, config: newConfig });
    }, [dispatch])
    const loadConfigData = useCallback(async () => {

        try {
            const configLogs = await getLoyaltyConfig();
            dispatch({ type: DataContextActions.GET_LOYALTY_CONFIG, config: configLogs });

        } catch (e) {
            console.error(e);
        }

    }, [dispatch])

    const setTier = useCallback((newTier) => {
        dispatch({ type: DataContextActions.GET_TIERS, config: newTier });
    }, [dispatch])
    const loadTiers = useCallback(async () => {

        try {
            dispatch({ type: DataContextActions.SET_LOADING_TIERS, status: true });
            const tiersResponse = await getTiers();
            dispatch({ type: DataContextActions.GET_TIERS, tiers: tiersResponse });

        } catch (e) {
            console.error(e);
            dispatch({ type: DataContextActions.SET_LOADING_TIERS, status: false });
        }

    }, [dispatch])

    // const updateConfigData = useCallback(async () => {
    //     try {
    //         const config = omit(state.config, ['createdOn', 'updatedOn', 'ownerId']);
    //         const configLogs = await updateConfig(config);
    //         dispatch({
    //             type: DataContextActions.UPDATE_LOYALTY_CONFIG,
    //             config: configLogs
    //         });
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }, [dispatch])


    const loadContactAttributes = useCallback(async () => {
        try {
            dispatch({
                type: DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES,
                status: true
            });
            const contactAttributeResponse = await getContactAttributes();

            dispatch({
                type: DataContextActions.SET_CONTACT_ATTRIBUTES,
                contactAttributes: contactAttributeResponse.attributes,
                tags: contactAttributeResponse.tags
            });


        } catch (e) {
            console.error(e);
            dispatch({
                type: DataContextActions.SET_LOADING_CONTACT_ATTRIBUTES,
                status: false
            });
        }
    }, [dispatch]);
    useEffect(() => {
        if (isAuth) {
            loadConfigData();
            loadContactAttributes();
            loadTiers()
        }
    }, [isAuth])


    const value = { ...state, loadConfigData, loadContactAttributes, setConfig, setTier, loadTiers };
    console.debug("Data Context: ", state)

    return (
        <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
    );
}

const DataContextConsumer = DataContext.Consumer;

export { DataContext, DataContextProvider, DataContextConsumer, DataContextActions };

















/*
const DataContext = React.createContext()

const initialState = {
    columnNameMap: {}
};

const DataContextActions = {
    RECEIVE_COLUMN_NAME_MAP : 'RECEIVE_COLUMN_NAME_MAP'
};

const dataReducer = (state, action) => {
    switch (action.type) {
        case DataContextActions.RECEIVE_COLUMN_NAME_MAP: {
            return {...initialState,
                columnNameMap: { $set: action.columnNameMap }
            };
        }
        default:
            return state;
    }
};


const DataContextProvider =  withRouter((props) =>{
    const [state, dispatch] = useReducer(dataReducer, initialState);

    const customDispatch = useCallback(async (action) => {

        switch (action.type) {
            case DataContextActions.RECEIVE_COLUMN_NAME_MAP: {
                let user = firebase.auth().onAuthStateChanged(user);
                return (dispatch) => {
                let contactAttrRef = firebase.database().ref("contact_attributes").child(user.uid);
                contactAttrRef.on('value', function (snapshot) {
                    dispatch({
                            type: DataContextActions.RECEIVE_COLUMN_NAME_MAP,
                            columnNameMap: snapshot.val() || {}
                        });
                })
            }
            break;
        }
            default: {
                dispatch(action);
            }
        }
    }, [props.history]
    );

    const value = { state, dispatch: customDispatch };
    return (
        <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
    );
});
const DataContextConsumer = DataContext.Consumer;

export { DataContext, DataContextProvider, DataContextConsumer, DataContextActions };
*/