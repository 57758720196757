import React, {useCallback, useContext} from 'react'
import { SideNavigationBar, Image } from 'shoutout_themes';
import { faChartLine, faTachometerAlt, faUsers,faGift,faDollarSign,faMedal,faCogs, faCoins, faSignOutAlt, faUser, faBell } from 'shoutout_themes/es/FaIconsSolid';
import { useHistory, useLocation} from "react-router-dom";
import './Navigation.css';

const Navigation=()=> {
    const history = useHistory();
    const location = useLocation();

    const onClickNavButton=useCallback((path)=>{
        history.push(path) 
    }, [history])

    return (
       <div className="sidebar-wrapper bg-black shadow-lg h-100">
           <SideNavigationBar>
                <div className="side-navigation-nav-link py-3 rounded-circle">
                    <Image display="" src='/assets/images/logo_dark.png' alt="ShoutOUT" width={40} />
                </div>
 
                <SideNavigationBar.NavLink active={location.pathname === '/'} icon={faTachometerAlt} name="Dashboard" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/members')} icon={faUsers} name="Members" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/members')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/points')} icon={faCoins} name="Points" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/points')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/rewards')} icon={faGift} name="Rewards" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/rewards')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/tiers')} icon={faMedal} name="Tiers" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/tiers')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/transactions')} icon={faDollarSign} name="Transactions" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/transactions')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/notifications')} icon={faBell} name="Notifications" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/notifications')}}/>
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/analytics')} icon={faChartLine} name="Analytics" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/analytics')}}/>
                {/*<SideNavigationBar.NavLink active={location.pathname.startsWith('/configurations')} icon={faCogs} name="Configuration" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/configurations')}}/> */}
                <SideNavigationBar.NavLink active={location.pathname.startsWith('/profile')} icon={faUser} name="Profile" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/profile')}}/>
            </SideNavigationBar>
    </div>
    )
}

export default Navigation