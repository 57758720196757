import { fetchGet, fetchPut, fetchPost, fetchDelete } from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const createPoints = (pointObj) => {
    return fetchPost(BASE_URL + "pointrules", pointObj);
}

const getPoints = () => {
    return fetchGet(BASE_URL + "pointrules");
}

const editPoints = ({ _id, name, status, metadata, type }) => {
    return fetchPut(`${BASE_URL}pointrules/${_id}`, { name, status, metadata, type });
}

const deletePoints = (pointId) => {
    return fetchDelete(BASE_URL + "pointrules/" + pointId);
}

export { createPoints, getPoints, editPoints, deletePoints }
