const DefaultContext = {
    logo: "assets/images/shoutout_logo.png",
    keycloakConfig:"/keycloak.json",
    baseUrl:"https://enterprise.api.topnotch.club/api/"
}
const DimahContext = {
    logo: "assets/dilmah/images/dilmah.svg",
    keycloakConfig:"/assets/dilmah/keycloak.json",
    baseUrl:"https://loyaltyapi-uat.dilmaht-lounge.com/api/"
}

let context;


switch (process.env.REACT_APP_BUILD_VARIANT) {
    case "dilmah": {
        context = DimahContext;
        break;
    }
    default: {
        context = DefaultContext;
        break;
    }
}

export default context;