import React, { useEffect} from 'react'
import { Button} from 'shoutout_themes';

const LoginPage = (props) => {
    useEffect(() => {
        console.debug("USE EFFECT")
        window.location.replace(`https://getshoutout.com/logout?referrer=${window.location.origin}`);
    }, []);
    return (<div className="mt-5 text-center">
        <h1 className="text-center">Redirecting...</h1>
        <Button variant="info">Go to Login</Button>
    </div>)
}

export default LoginPage
