import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import * as firebase from 'firebase/app';
import { BrowserRouter as Router} from "react-router-dom";

/*const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA0u47etXNbMye-ba8fT6fwjvNSnFiBJFM",
  authDomain: "shoutout-82d85.firebaseapp.com",
  databaseURL: "https://shoutout-82d85.firebaseio.com",
  storageBucket: "shoutout-82d85.appspot.com",
  messagingSenderId: "406431960011"
};

firebase.initializeApp(FIREBASE_CONFIG); */

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
