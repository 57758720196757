import { fetchGet, fetchPut, fetchPost } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}coreservice/`;//`${Constants.BASE_URL}loyaltyservice2/`;

const getNotifications = () => {
    return fetchGet(`${BASE_URL}notifications`);
};

const createNotifications = ({ templateId, ...rest }) => {
    return fetchPost(`${BASE_URL}notifications`, rest);
};

const updateNotifications = ({ _id: id, createdOn, createdBy, updatedOn, ownerId, isVisible, __v, type, templateId, ...rest }) => {
    return fetchPut(`${BASE_URL}notifications/${id}`, rest);
};

export { getNotifications, createNotifications, updateNotifications }