import { fetchGet,jsonToQueryParam } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getPointsRates = (queryObject) => {
    return fetchGet(`${BASE_URL}pointsstats/rates?${jsonToQueryParam(queryObject)}`);
};

const getPointsCount = (queryObject) => {
    return fetchGet(`${BASE_URL}pointsstats/counts?${jsonToQueryParam(queryObject)}`);
};

const getPointsSeries = (queryObject) => {
    return fetchGet(`${BASE_URL}pointsstats/series?${jsonToQueryParam(queryObject)}`);
};

const getBranchWiseCollectedPoint = (queryObject) => {
    return fetchGet(`${BASE_URL}pointsstats/collectdistributed?${jsonToQueryParam(queryObject)}`);
};

const getBranchWiseRedeemedPoint = (queryObject) => {
    return fetchGet(`${BASE_URL}pointsstats/redeemdistributed?${jsonToQueryParam(queryObject)}`);
};

const getHeatMapStat = (queryObject) => {
    return fetchGet(`${BASE_URL}salestats/heatmap?${jsonToQueryParam(queryObject)}`);
};

const getSalesSummary = (queryObject) => {
    return fetchGet(`${BASE_URL}salestats/summary?${jsonToQueryParam(queryObject)}`);
};

const getRewardsCount = (timePeriod) => { // fromDate, toDate
    return fetchGet(`${BASE_URL}rewardsstats/counts?${jsonToQueryParam(timePeriod)}`);
};

const getRewardsSeries = (timePeriod) => {
    return fetchGet(`${BASE_URL}rewardsstats/series?${jsonToQueryParam(timePeriod)}`);
};

const getRewardRedemptionSeries = (timePeriod) => {
    return fetchGet(`${BASE_URL}rewardsstats/redemptions?${jsonToQueryParam(timePeriod)}`);
};

const getTopRewards = (timePeriod) => {
    return fetchGet(`${BASE_URL}rewardsstats/topusedrewards?${jsonToQueryParam(timePeriod)}`);
};

// const getDailyPeopleReports = (timePeriod) => {
//     return fetchGet(`${BASE_URL}dailypeoplereports/active?${jsonToQueryParam(timePeriod)}`);
// };

const getUserSummary = (timePeriod) => {
    return fetchGet(`${BASE_URL}usersstats/summary?${jsonToQueryParam(timePeriod)}`);
};

const getUserCount = (timePeriod) => {
    return fetchGet(`${BASE_URL}usersstats/counts?${jsonToQueryParam(timePeriod)}`);
};

const getUserDistribution = (queryObj) => {
    return fetchGet(`${BASE_URL}usersstats/distributioncounts?${jsonToQueryParam(queryObj)}`);
};

const getUserDistributionSources = (queryObj) => {
    return fetchGet(`${BASE_URL}usersstats/distributionseries?${jsonToQueryParam(queryObj)}`);
};

const getUserSeries = (timePeriod) => {
    return fetchGet(`${BASE_URL}usersstats/series?${jsonToQueryParam(timePeriod)}`);
};

const getUserSeriesReturn = (timePeriod) => {
    return fetchGet(`${BASE_URL}usersstats/seriesreturn?${jsonToQueryParam(timePeriod)}`);
};


export { getPointsRates, 
        getHeatMapStat, 
        getSalesSummary, 
        getPointsCount, 
        getPointsSeries, 
        getBranchWiseCollectedPoint, 
        getBranchWiseRedeemedPoint, 
        getRewardsCount, 
        getRewardsSeries,
        getRewardRedemptionSeries,
        getTopRewards,
        //getDailyPeopleReports,
        getUserSummary,
        getUserCount,
        getUserDistribution, 
        getUserSeries,
        getUserDistributionSources,
        getUserSeriesReturn
    };
