import { faFacebookMessenger, faFacebook, faWpforms } from 'shoutout_themes/es/FaIconsBrands';
 import AppContext from './AppContext';
//const BASE_URL = "https://hu290cdxk8.execute-api.us-east-1.amazonaws.com/";

const BASE_URL = AppContext.baseUrl;


const APP_LOYALTY = "LOYALTY";
const APP_ENGAGEMENT = "ENGAGE";
const APP_LITE = "LITE";
const APP_COLLECT = "COLLECT";
const APP_GAMIFICATION = "POLL";

const OPTOUT_TEMPLATES = {
    TEMPLATE1: "*SMS STOP {{keyword}} to {{mobileNumber}}",
    TEMPLATE2: "*SMS STOP to {{mobileNumber}}",
    TEMPLATE3: "*Reply STOP to opt-out",
}


const activityTypes = {
    REGISTER_LOYALTY: {
        name: "Register Loyalty",
        templateId: 12
    },
    COLLECT_POINTS: {
        name: "Earned Points",
        templateId: 10
    },
    REDEEM_POINTS: {
        name: "Redeemed Points",
        templateId: 11
    },
    REDEEM_REWARD: {
        name: "Redeem Reward",
        templateId: 13
    },
    CLAIMED_REWARD: {
        name: "Claimed Reward",
        templateId: 23
    },
    COUPON_ISSUED: {
        name: "Coupon Issued",
        templateId: 15
    },
}

export default {
    BASE_URL ,
    TRANSPORT_SMS: 'SMS',
    TRANSPORT_WHATSAPP: 'WHATSAPP',
    TRANSPORT_MESSENGER: 'FACEBOOK_MESSENGER',
    MAX_SMS_LENGTH: 459,
    MAX_SMS_PAGES: 3,
    TRANSPORT_EMAIL: 'EMAIL',
    CAMPAIGN_BROADCAST: 'BROADCAST',
    CAMPAIGN_TRIGGER: 'EVENT',
    CONTACTS_LOAD_BATCH_SIZE: 100,
    CAMPAIGN_STATUS: {

        RUNNING: {
            name: 'Running',
            icon: 'hourglass',
            className: 'status-running',
            showReport: false,
            enabledDelete: false
        },

        SCHEDULED: {
            name: 'Scheduled',
            icon: 'clock',
            className: 'status-scheduled',
            showReport: false,
            enabledDelete: true
        },
        FAILED: {
            name: 'Failed',
            icon: 'times-circle',
            className: 'status-failed',
            showReport: true,
            enabledDelete: true
        },
        REJECTED: {
            name: 'Rejected',
            icon: 'ban',
            className: 'status-failed',
            showReport: false,
            enabledDelete: true
        },
        REVIEWING: {
            name: 'Reviewing',
            icon: 'search',
            className: 'status-warning',
            showReport: false,
            enabledDelete: false
        },
        CREATING: {
            name: 'Creating',
            icon: 'sync',
            className: 'status-creating',
            showReport: false,
            enabledDelete: false
        },

        INSUFFICIENT_CREDITS: {
            name: 'Insufficient Credits',
            icon: 'exclamation-triangle',
            className: 'status-warning',
            chargingFailed: true,
            showReport: true,
            enabledDelete: true
        },

        FINISHED: {
            name: 'Finished',
            icon: 'check-circle',
            className: 'status-finished',
            showReport: true,
            enabledDelete: true
        },

        LISTENING: {
            name: 'Listening',
            icon: 'assistive-listening-systems',
            className: 'status-listening',
            showReport: true,
            enabledDelete: true
        },

    },
    EVENT_TEMPLATES: {
        DEFAULT: 0,
        LOYALTY_POINT_COLLECTED: 10,
        LOYALTY_POINT_REDEEMED: 11,
        LOYALTY_REGISTERED: 12,
        LOYALTY_REWARD_REDEEMED: 13,
        ENGAGEMENT_CAMPAIGN_CREATED: 14,
        ENGAGEMENT_COUPON_ISSUED: 15,
        ENGAGEMENT_FORM_RESPONSE: 16,
        ENGAGEMENT_CUSTOM_EVENT: 17,
        MESSAGE_INCOMING_SMS: 18,
        MESSAGE_OUTGOING_SMS: 19,
        MESSAGE_OUTGOING_EMAIL: 20,
        MESSAGE_INCOMING_EMAIL: 21
    },
    LOG_STATUS_MAP: {
        '1001': 'SENT', //successfully submitted
        '1002': 'FAILED', //Invalid url
        '1003': 'FAILED', //Authentication failed
        '1004': 'FAILED', //Invalid data for type field
        '1005': 'FAILED', //Invalid data for message field
        '1006': 'INVALID DESTINATION', //Invalid MSISDN
        '1007': 'INVALID SENDER ID', //Invalid data for source field
        '1008': 'FAILED', //Invalid data for dlr field
        '1010': 'FAILED', //Internal error
        '1011': 'FAILED', //Response timeout
        '1012': 'FAILED', //No routes
        '1013': 'UNKNOWN',
        '1014': 'BOUNCED',
        '1015': 'BLOCKED',
        '1016': 'DELIVERED',
        '1025': 'NO CREDITS', //Insufficient credit
        '1026': 'SUBMITTED'
    },
    MSG_STATUS_TO_CODE_MAP: {
        'SENT': '1001',
        'INVALID_SENDER_ID': '1007',
        'NO_CREDITS': '1025',
        'INVALID_RECEIVER': '1006',
        'SUBMITTED': '1026'
    },
    ALL_SEGMENT: {
        id: "0",
        segment: {
            name: 'All', data: [{
                "columnName": "id",
                "_selectedFilter": "4",
                "searchKey": "",
                "columnType": "string"
            }]
        }
    },
    RESERVED_ATTRIBUTES: ['user_id', 'mobile_number', 'email', '_created_on', 'name', 'tags', 'last_seen_on', '_modified_on', 'id'],
    ENGAGE_APP_URL: 'https://web.getshoutout.com',
    LOYALTY_APP_URL: 'https://app.topnotch.club',
    
    CAMPAIGN_PROGRESS_STATUS: {
        PENDING: 0,
        IN_PROGRESS: 1,
        SUCCESS: 2,
        FAILED: 3
    },
    SHORT_URL_DOMAIN: 'https://e-url.co',
    BUILD_APP: process.env.REACT_APP_PRODUCT, //"LOYALTY or ENGAGE"
    APP_LOYALTY,
    APP_ENGAGEMENT,
    APP_LITE,
    APP_COLLECT,
    APP_GAMIFICATION,
    INTEGRATION_APPS: {
        FACEBOOK_MESSENGER: {
            id: 'FACEBOOK_MESSENGER',
            icon: faFacebookMessenger,
            name: 'Facebook Messenger',
            path: 'fbmessenger',
            description: 'Connect with your customers via Facebook Messenger triggered messages',
            label: 'Beta',
            //component: MessengerApp
        },
        FACEBOOK_LEAD_AD: {
            id: 'FACEBOOK_LEAD_AD',
            icon: faFacebook,
            name: 'Facebook Lead Ad',
            path: 'fbleadad',
            //component: FacebookLeadAd
        },
        SHOUTOUT_FORM: {
            id: 'SHOUTOUT_FORM',
            name: 'ShoutOUT Forms',
            path: 'shoutoutforms'
        },
        TYPEFORM_APP: {
            id: 'TYPEFORM',
            icon: faWpforms,
            name: 'typefrom app',
            path: 'typeform',
            //component: typeForm
        },
        SHOUTOUT_POLL: {
            id: 'SHOUTOUT_POLL',
            name: 'ShoutOUT Poll',
            path: 'shoutoutpoll'
        },
        WHATSAPP: {
            id: 'WHATSAPP',
            name: 'WhatsApp',
            path: 'whatsapp'
        }
    },
    SMS_OPTOUT: {
        TYPE_TRANSACTIONAL: 'transactional',
        TYPE_BOTH: 'both',
        TYPE_PROMOTIONAL: 'promotional',
        TEMPLATE1: OPTOUT_TEMPLATES.TEMPLATE1,
        TEMPLATE2: OPTOUT_TEMPLATES.TEMPLATE2,
        TEMPLATE3: OPTOUT_TEMPLATES.TEMPLATE3,
        COUNTRIES: {
            "all": {//specify the max support length number format
                template: OPTOUT_TEMPLATES.TEMPLATE1,
                mobileNumber: "0777123456",
                keyword:"ABC",
                optoutKeywordRequired: true
            },
            "sri lanka": {
                mobileNumber: "0777123456",
                template: OPTOUT_TEMPLATES.TEMPLATE1,
                keyword:"ABC",
                optoutKeywordRequired: true
            },
            "united states": {
                mobileNumber: "4431234567",
                template: OPTOUT_TEMPLATES.TEMPLATE3,
                optoutKeywordRequired: false
            },
            "australia": {
                mobileNumber: "04xxxxxxxx",
                template: OPTOUT_TEMPLATES.TEMPLATE3,
                keyword:"ABC",
                optoutKeywordRequired: true
            }
        }
    },
    USER_ACCESS_CONTROLS: {
        ACCESS_KEY: {
            DELETE: "delete",
            READ: "read",
            WRITE: "write"
        },
        MODULES: {
            APPS: {
                moduleId: "apps"
            },
            CAMPAIGNS: {
                moduleId: "campaigns"
            },
            COMMON: {
                moduleId: "common"
            },
            LITE_MESSAGES: {
                moduleId: "litemessages"
            },
            LOYALTY_ANALYTICS: {
                moduleId: "loyaltyanalytics"
            },
            LOYALTY_CONFIGURATIONS: {
                moduleId: "loyaltyconfigurations"
            },
            LOYALTY_POINTS: {
                moduleId: "loyaltypoints"
            },
            LOYALTY_REWARDS: {
                moduleId: "loyaltyrewards"
            },
            LOYALTY_TRANSACTIONS: {
                moduleId: "loyaltytransactions"
            },
            LOYALTY_USERS: {
                moduleId: "loyaltyusers"
            },
            PEOPLE: {
                moduleId: "people"
            },
            POLL_COMPETITION: {
                moduleId: "pollcompetitions"
            },
            POLL_EXPORTS: {
                moduleId: "pollexports"
            },
            POLL_REPORTS: {
                moduleId: "pollreports"
            },
            RESPONSE: {
                moduleId: "response"
            },
            ROOT: {
                moduleId: "root"
            },
            SYSTEM: {
                moduleId: "system"
            }

        }
    },
    activityTypes
};
