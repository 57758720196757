import { fetchGet, fetchPut, fetchPost } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

    const getTiers = () => {
        return fetchGet(BASE_URL + "tiers");
    }

    const editTiers = ({_id, name, benefits, imageUrl, points }) => {
        return fetchPut(`${BASE_URL}tiers/${_id}`, {name, benefits, imageUrl, points});
    }

    const createTiers = ({name, benefits, imageUrl, points }) => {
        return fetchPost(`${BASE_URL}tiers`, {name, benefits, imageUrl, points});
    }


export { getTiers, editTiers, createTiers}