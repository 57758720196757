import { fetchGet } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}`;//`${Constants.BASE_URL}loyaltyservice2/`;

const getTransactions = (fromDate = '', toDate = '') => {
    return fetchGet(BASE_URL + "loyaltyservice/transactions?fromDate=" + fromDate + "&toDate=" + toDate);
}


export { getTransactions }