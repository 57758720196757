import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import './Home.css';
const DashboardPage = lazy(() => import('../dashboard/DashboardPage'));
const MembersPage = lazy(() => import('../members/MembersPage'));
const PointsPage = lazy(() => import('../points/PointsPage'));
const RewardsPage = lazy(() => import('../rewards/RewardsPage'));
const RewardDetails = lazy(() => import('../rewards/rewardDetails/RewardDetails'));
const TiersPage = lazy(() => import('../tiers/TiersPage'));
const TransactionsPage = lazy(() => import('../transactions/TransactionsPage'));
const AnalyticsPage = lazy(() => import('../analytics/AnalyticsPage'));
// const Configurations = lazy(() => import('../configurations/Configurations'));
// const ProfilePage = lazy(() => import('../members/profile/ProfilePage'));
const MyProfilePage = lazy(() => import('../myProfile/MyProfilePage'));
const CreateRewardWizardPage = lazy(() => import('../rewards/createRewards/CreateRewardWizard'));
const CreateTierPage = lazy(() => import('../tiers/createTiers/CreateTierPage'));
const PointsCreatePage = lazy(() => import('../points/createPoints/CreatePointsPage'));
const NotificationPage = lazy(() => import('../notification/NotificationPage'));

const Home = () => {
    return (
        <div className="home-wrapper d-flex">
            <Navigation />
            <div className="home-content flex-fill d-flex flex-column">
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route name="Dashboard" exact path="/" component={DashboardPage} />
                        <Route name="Members" path="/members" component={MembersPage} />
                        <Route name="PointsCreate" exact path="/points/create" component={PointsCreatePage} />
                        <Route name="Points" exact path="/points" component={PointsPage} />
                        <Route name="CreateRewards" exact path="/rewards/create-reward" component={CreateRewardWizardPage} />
                        <Route name="RewardsDetails" exact path="/rewards/:id" component={RewardDetails} />
                        <Route name="Rewards" exact path="/rewards" component={RewardsPage} />
                        <Route name="CreateTiers" exact path="/tiers/create-tier" component={CreateTierPage} />
                        <Route name="Tiers" exact path="/tiers" component={TiersPage} />
                        <Route name="Transactions" exact path="/transactions" component={TransactionsPage} />
                        <Route name="Notifications" exact path="/notifications" component={NotificationPage} />
                        <Route name="Analytics" path="/analytics" component={AnalyticsPage} />
                       {/* <Route name="Configurations" exact path="/configurations" component={Configurations} /> */}
                        <Route name="Profile" exact path="/profile" component={MyProfilePage} />
                    </Switch>
                </Suspense>
            </div>
        </div>
    )
}

export default Home
