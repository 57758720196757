import { fetchGet, fetchPost } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}accountservice/`;
const getSenderIds = () => {
    return fetchGet(`${BASE_URL}accounts/senders`);
}

const getProfile = () => {
    return fetchGet(`${BASE_URL}accounts/users`);
}

const updateProfile = (profileData) => {
    return new Promise(resolve => {
        setTimeout(() => { resolve() }, 3000)
    })
    return fetchPost(`${BASE_URL}`)
}
export { getSenderIds, getProfile, updateProfile };